
























































































import { Component, Vue } from 'vue-property-decorator';
import { readUserProfile } from '@/store/main/getters';
import { readUserWhitelistAddresses } from '@/store/main/getters';
import { readConstants } from '@/store/main/getters';
import { dispatchGetConstants } from '@/store/main/actions';
import { dispatchGetUserWhitelistAddresses, dispatchCreateUserWhitelistAddresses, dispatchUpdateUserWhitelistAddresses, dispatchDeleteUserWhitelistAddresses } from '@/store/main/actions';

@Component
export default class Wallet extends Vue {
  public dialog = false
  public dialogDelete = false

  public headers = [
    {
      text: '名稱',
      // align: 'start',
      // sortable: false,
      value: 'name',
    },
    { text: '地址', value: 'address' },
    { text: '', value: 'actions', sortable: false },
  ]

  get whitelist_addresses() {
    return readUserWhitelistAddresses(this.$store);
  }

  public editedIndex = -1
  public editedItem = {
    name: '',
    address: '',
  }
  public defaultItem = {
    name: '',
    address: '',
  }

  editItem (item) {
    this.editedIndex = this.whitelist_addresses.indexOf(item)
    this.editedItem = Object.assign({}, item)
    this.dialog = true
  }

  deleteItem (item) {
    this.editedIndex = this.whitelist_addresses.indexOf(item)
    this.editedItem = Object.assign({}, item)
    this.dialogDelete = true
  }

  async deleteItemConfirm () {
    // delete object
    console.log("Object deleted: ", this.editedIndex)
    // this.whitelist_addresses.splice(this.editedIndex, 1)
    await dispatchDeleteUserWhitelistAddresses(this.$store, {address_id: this.editedIndex});
    this.closeDelete()
  }

  close () {
    this.dialog = false
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    })
  }

  closeDelete () {
    this.dialogDelete = false
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    })
  }

  async save () {
    if (this.editedIndex > -1) {
      // edit object
      console.log("Object edited: ", this.editedIndex)
      // Object.assign(this.whitelist_addresses[this.editedIndex], this.editedItem)
      await dispatchUpdateUserWhitelistAddresses(this.$store, {address_id: this.editedIndex, name: this.editedItem.name, address: this.editedItem.address});
    } else {
      // new object
      console.log("Object created: ", this.editedIndex)
      // this.whitelist_addresses.push(this.editedItem)
      await dispatchCreateUserWhitelistAddresses(this.$store, this.editedItem);
    }
    this.close()
  }

  get formTitle () {
    return this.editedIndex === -1 ? '新增地址' : '修改地址'
  }

  public addressRules() {
    return [(v) => !!v || '請填寫地址',
            (v) => (v.startsWith('T') || v.startsWith('0x')) || '地址應以 T 或 0x 開頭',]
          
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  async mounted() {
    await dispatchGetConstants(this.$store);
    await dispatchGetUserWhitelistAddresses(this.$store);
  }

  // update version
  checkVersion() {
    const constants = readConstants(this.$store);

    let onlineVersion = "none";
    if (constants) {
      onlineVersion = constants.version;
    }

    let localVersion = localStorage.getItem('vers');
    let updateSignal = localStorage.getItem('upt');

    // check if we need to fresh the browser
    if (onlineVersion != "none" && localVersion != onlineVersion) { // detect newer online version
      localStorage.setItem('vers', onlineVersion);
      window.location.reload();
    } else if (updateSignal == 'refresh') { // detect refresh signal
      localStorage.setItem('upt', 'no');
      window.location.reload();
    }
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
    }
  }

  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.checkVersion();
  }

  onResize() {
    this.isMobile = window.innerWidth < 960;
  }
}
